import { useTranslation } from "@i18n";
import { CDN_LINK } from "@globalConfig";
import stl from "./Footer.module.css";
import useHelpers from "@hooks/useHelpers";
import {useLang} from "@/common/Contexts/LanguageContext";

function Footer(props) {
  const { t } = useTranslation();
  const lang = useLang();
  const {createUrl} = useHelpers();

  return (
    <footer
      style={{
        borderTop: props.hideFooterWithoutAuthMsg ? "none" : undefined,
      }}
      className={[
        stl.footer,
        props.hideFooterWithoutAuthMsg
          ? stl.hideFooterWithoutAuthMsg
          : undefined,
      ].join(" ")}>
      {!props.hideFooterWithoutAuthMsg && (
        <>
          {" "}
          <div className="container">
            <span
              className="icon-ftrLogo"
              style={{
                fontSize: "90px",
                display: "block",
                marginTop: "-50px",
                marginBottom: "-22px",
              }}/>
            <div className={stl.dataFooterContent}>
              <div>
                <ul>
                  <li>
                    <a
                      href={createUrl("/")}
                      className="interActiveGray">
                      {t("الرئيسية", { ns: "web_footer" })}
                    </a>
                  </li>
                  <li>
                    <a
                      className="interActiveGray"
                      href={createUrl("/about-us")}>
                      {t("من نحن", { ns: "web_footer" })}
                    </a>
                  </li>
                  <li>
                    <a
                      className="interActiveGray"
                      href={createUrl("/contact-us")}>
                      {t("اتصل بنا", { ns: "web_footer" })}
                    </a>
                  </li>
                  <li>
                    <a
                      href={`https://syarah.com/carsguide/${lang=== 'en' ? 'en/' : ''}`}
                      className="interActiveGray">
                        {t("دليل سيارة", { ns: "web_footer" })}
                    </a>
                  </li>
                  <li>
                    <a
                      className="interActiveGray"
                      href={createUrl("/network")}
                      rel="noopener noreferrer">
                      {t("برنامج سيارة للتسويق بالعمولة", {
                        ns: "web_footer",
                      })}{" "}
                    </a>
                  </li>
                  <li>
                    <a
                      className="interActiveGray"
                      href={createUrl("/trend")}
                      rel="noopener noreferrer">
                      {t("سيارة ترند", {
                        ns: "web_footer",
                      })}
                    </a>
                  </li>
                </ul>
                <p className="interActiveGray">
                  {t("شركة سعوديه بسجل تجاري 1010538980 مصدره الرياض", {
                    ns: "web_footer",
                  })}
                </p>
                <div>
                  <a
                    className="interActiveGray"
                    href={createUrl("/terms-landing")}
                    rel="noopener noreferrer">
                    {t("الأحكام والشروط", { ns: "web_footer" })}{" "}
                  </a>{" "}
                  |
                  <a
                    className="interActiveGray"
                    href={createUrl("/privacy-policy")}
                    rel="noopener noreferrer">
                    {t("سياسة الخصوصية", { ns: "web_footer" })}{" "}
                  </a>
                </div>
              </div>
              <div>
                <strong className={"greenGrd"}>
                  <span
                    className="icon-greenArmor"
                    style={{ fontSize: "17px" }}></span>
                  <span>
                    {t("طرق دفع إلكترونية آمنة", { ns: "web_footer" })}
                  </span>
                </strong>
                <ul className={stl.payment}>
                  <li>
                    <span className="icon-Mada" style={{ fontSize: "44px" }}>
                      <span className="path1"></span>
                      <span className="path2"></span>
                      <span className="path3"></span>
                      <span className="path4"></span>
                      <span className="path5"></span>
                      <span className="path6"></span>
                      <span className="path7"></span>
                      <span className="path8"></span>
                      <span className="path9"></span>
                    </span>
                  </li>
                  <li>
                    <span
                      className="icon-Visadark"
                      style={{ fontSize: "44px" }}>
                      <span className="path1"></span>
                      <span className="path2"></span>
                      <span className="path3"></span>
                      <span className="path4"></span>
                      <span className="path5"></span>
                      <span className="path6"></span>
                    </span>
                  </li>
                  <li>
                    <span className="icon-mc" style={{ fontSize: "44px" }}>
                      <span className="path1"></span>
                      <span className="path2"></span>
                      <span className="path3"></span>
                      <span className="path4"></span>
                      <span className="path5"></span>
                      <span className="path6"></span>
                    </span>
                  </li>
                  <li>
                    <span
                      className="icon-apple_pay"
                      style={{ fontSize: "44px" }}>
                      <span className="path1"></span>
                      <span className="path2"></span>
                    </span>
                  </li>

                  <li>
                    <span className="icon-Sadad" style={{ fontSize: "44px" }}>
                      <span className="path1"></span>
                      <span className="path2"></span>
                      <span className="path3"></span>
                      <span className="path4"></span>
                    </span>
                  </li>
                  <li>
                    <span
                      className="icon-BankTransfer"
                      style={{ fontSize: "44px" }}>
                      <span className="path1"></span>
                      <span className="path2"></span>
                      <span className="path3"></span>
                      <span className="path4"></span>
                      <span className="path5"></span>
                    </span>
                  </li>
                </ul>
                <p className="interActiveGray">
                  {t("جميع الحقوق محفوظة لشركة موقع سيارة المحدودة © ", {
                    ns: "web_footer",
                  })}
                  2020
                </p>
              </div>
            </div>
          </div>
        </>
      )}
      {props.authenticationMsg && (
        <div className={stl.authenticationMsg}>
          <div className={[stl.container, "container"].join(" ")}>
            <p>
              <span
                className="icon-greenArmor"
                style={{ fontSize: "17px" }}></span>
              {t(
                "شركة موقع سيارة للتجارة شركة موثقة لدى وزارة التجارة والاستثمار وبدعم من شركة علم, بسجل تجاري رقم 1010538980",
                {
                  ns: "web_footer",
                }
              )}
            </p>
            <div className={stl.footerWithVat}>
              <a
                className={stl.vatLink}
                href={
                  "/assets/bundles/GAZT_VAT_Certificate.pdf?_ga=2.254421774.762592950.1694324889-1354543137.1691484927"
                }
                target="_blank">
                <img
                  width="30px"
                  height={"38px"}
                  src={CDN_LINK + "/assets/images/Vat.svg"}
                  alt="vat icon"
                />
              </a>
              <img
                src={CDN_LINK + "/assets/images/saudi_elm.svg"}
                alt="saudi_elm icon"
                width={"262px"}
                height={"44px"}
              />
            </div>
          </div>
        </div>
      )}
    </footer>
  );
}

export default Footer;
